export interface EnvironmentInterface {
  name: string;
  production: boolean;
  url: ApiUrl;
  baseApiUrl: string;
  daDataToken: string;
  sentryDsn: string;
  productionUrl: string;
  appKeyAndroid: string;
  appKeyIOS: string;
  apiCrm: string;
  appMetricaId: string;
  oneSignal?: {
    serverKey: string;
    senderID: string;
    appID: string;
  };
}

export class ApiUrl {
  rudderstack = '/rudderstack';
  password = '/password';
  auth = '/v2/auth';
  refresh = '/refresh';
  profile = '/profile';
  account = '/account';
  clients = '/clients';
  minimumPrices = '/minimum-prices';
  minimumPricesPe = '/minimum-prices-pe';
  subscriptionPrice = '/v2/subscription-price';
  availableDiscount = '/program-discounts/discounts-for-program-lengths';
  subscription = '/subscription';
  subscriptionPEList = '/subscription-pe-list';
  availableStartDaysOnMonth = '/available-start-days-on-month';
  availableStartDaysOnMonthProstoeda = '/available-start-days-on-month-prostoeda';
  availableStartDateAfterLastPackage = '/available-start-date-after-last-package';
  availableStartDateAfterLastPackageProstoeda = '/available-start-date-after-last-package-prostoeda';
  deliverySchedule = '/deliverySchedule';
  deliveryInterval = '/delivery-interval';
  subscriptionInfoByPayment = '/subscription/get-info-by-payment';
  subscriptionInfoById = '/subscription/get-info-by-id';
  deleteFirstUnpaid = '/subscription/delete-first-unpaid';
  availableMovingDays = '/available-days';
  menu = '/menu';
  checkEmail = '/profile/check-email';
  map = '/map';
  payToken = '/pay/token';
  payCryptogram = '/pay/cryptogram';
  payStartSession = '/pay/start-session';
  paymentInfo = '/pay/info';
  paymentSbp = '/pay/sbp';
  cards = '/cards';
  clientMenu = '/subscription/client-menu';
  pushNotification = '/push-notifications';
  feedBack = '/feedback';
  familySubscriptionTask = '/family-subscription-task';
}

export function generateApiUrlObject(baseApiUrl: string): ApiUrl {
  const result: ApiUrl = new ApiUrl();
  const endPointPrefix: string = 'apps/mobile';

  for (const key in result) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      result[key] = `${baseApiUrl}${endPointPrefix}${result[key]}`;
    }
  }

  return result;
}

export const developmentCommon = {
  sentryDsn: 'https://a0cc61517450379fb587c7980435e0d8@sentry.justfood.pro/5',
  productionUrl: 'https://justfood.pro',
  daDataToken: 'afebb160c11a053aa30e8920880815e8a88e2f81',
  appKeyAndroid: 'F4D03DE7-A89A-4D50-96E4-BAC7C9484847',
  appKeyIOS: 'C4F192F7-3371-494E-9D8A-5622BC42E86C',
  apiCrm: 'https://test.crm.justfood.pro/api',
  appMetricaId: '3c60ebca-ffcd-4a02-82f9-e44bfe409eca',
};
