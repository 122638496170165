import { ChangeDetectionStrategy, Component, inject, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { Observable, map, take } from 'rxjs';
import { Store } from '@ngxs/store';
import { Device } from '@capacitor/device';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { TimerService } from '@modules/login/services/timer.service';
import {
  AuthSelectors,
  InitAgreements,
  SendInputPhone,
  SetNotificationsAgreement,
  SetPrivacyAgreement,
  SetUrlAfterAuth,
} from '@store/auth';

@Component({
  templateUrl: './login-phone-input.component.html',
  styleUrls: ['./login-phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InAppBrowser],
})
export class LoginPhoneInputComponent implements OnInit {
  public isGettingCode$: Observable<boolean> = inject(Store).select(AuthSelectors.isGettingCode);
  public codeError$: Observable<string> = inject(Store).select(AuthSelectors.codeError);
  public notificationsAgreement$: Observable<boolean> = inject(Store).select(AuthSelectors.notificationsAgreement);
  public privacyAgreement$: Observable<boolean> = inject(Store).select(AuthSelectors.privacyAgreement);

  public phoneInputPrefix = '+7';
  public phoneErrorMessage = 'Введите номер телефона';

  public phoneNumberControl = new FormControl('', {
    validators: [Validators.required, Validators.minLength(10)],
  });

  public isTimeout$ = this.timerService?.timeLeft$.pipe(map(timeLeft => timeLeft > 0));

  public isShowAgreements$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private router: Router,
    private timerService: TimerService,
    private store: Store,
    private iab: InAppBrowser,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new InitAgreements());
    this.focusInput('auth-phone-input');

    this.isShowAgreements$ = this.privacyAgreement$.pipe(take(1));
  }

  public sendPhone(): void {
    if (this.phoneNumberControl.invalid) {
      this.phoneNumberControl.markAsTouched();
      return;
    }

    const phone = this.phoneNumberControl.value;

    this.store.dispatch(new SendInputPhone(phone));
  }

  public privacyAgreementClick(e: Event): void {
    const isChecked = (e.currentTarget as any).checked;

    this.store.dispatch(new SetPrivacyAgreement(isChecked));
  }

  public notificationsAgreementClick(e: Event): void {
    const isChecked = (e.currentTarget as any).checked;

    this.store.dispatch(new SetNotificationsAgreement(isChecked));
  }

  public goToSubList(): void {
    this.store.dispatch(new SetUrlAfterAuth(null));
    this.router.navigate(['cabinet', 'create']);
  }

  async openExternalLink(url: string) {
    const { platform } = await Device.getInfo();

    if (platform === 'web') {
      window.open(url, '_blank');
      return;
    }

    this.iab.create(url, '_system', 'location=yes');
  }

  private focusInput(className: string): void {
    setTimeout(() => {
      const input: HTMLInputElement = this.dom.querySelector(`.${className}`);

      if (input) {
        input.focus();
      }
    }, 1000);
  }
}
