import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ClientMenuDish, Feedback, FeedbackDish, FeedbackToSend } from '@modules/client-menu/models';
import { Store } from '@ngxs/store';
import { ImageTypeEnum } from '@shared/enums';
import { FeedbackApiService, NgOnDestroyService } from '@shared/services';
import { getFullRuDay, capitalize } from '@shared/utils';
import { UpdateDishFeedback } from '@store/client-menu';
import dayjs from 'dayjs';
import { BehaviorSubject, catchError, of, takeUntil, distinctUntilChanged, debounceTime, switchMap, Observable } from 'rxjs';

interface FeedbackDishDialogData {
  rating: number;
  feedback: Feedback;
  dish: ClientMenuDish;
}

@Component({
  selector: 'app-dish-rating',
  templateUrl: './dish-rating.component.html',
  styleUrls: ['./dish-rating.component.scss'],
})
export class DishRatingComponent implements OnInit {
  public readonly ImageTypeEnum = ImageTypeEnum;
  public questions$ = new BehaviorSubject<string[]>([]);
  public form: FormGroup;
  @Input() dish: ClientMenuDish;
  @Input() feedback: Feedback;
  @Input() data: FeedbackDishDialogData;
  @Input() rating: number;
  @Input() date: string;

  public get previousDishFeedback(): FeedbackDish {
    return this.dish?.dishFeedback;
  }

  public get isRatingSet(): boolean {
    return this.form?.get('rating')?.value > 0;
  }

  private get hasFeedback(): boolean {
    return !!this.previousDishFeedback?.feedbackId;
  }

  get day(): string {
    if (!this.date) {
      return '';
    }
    const ruDay = getFullRuDay(dayjs(this.date));

    return capitalize(ruDay);
  }

  @ViewChild('feedbackForm') private formRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private ngOnDestroy$: NgOnDestroyService,
    private feedbackApiService: FeedbackApiService,
    private store: Store,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.initSubscriptions();

    if (this.hasFeedback) {
      this.patchForm();
    }
  }

  public onSave(closeMobal: boolean = true, event?): void {
    if ((event !== 0 || event !== null) && event !== undefined) {
      this.form?.get('rating').patchValue(event);
    }
    this.save(event)
      .pipe(
        catchError(() => of(null)),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(() => {
        if (closeMobal) {
          this.closeModal();
        }
        this.updateDish();
      });
  }

  private updateDish(): void {
    const formData = this.form.getRawValue();
    const payload = {
      feedbackId: this.feedback?.feedbackId,
      rating: formData.rating,
      comment: formData.comment,
    };

    this.store.dispatch(new UpdateDishFeedback(this.dish, payload));
  }

  private initSubscriptions(): void {
    this.form
      .get('rating')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
      .subscribe((rating: number) => {
        this.setFeedbackVariants(rating);

        setTimeout(() => {
          this.scrollToVariants();
        }, 10);
      });

    this.form.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(() => this.save()),
        catchError(() => of(null)),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  private scrollToVariants(): void {
    const formElem = this.formRef.nativeElement as HTMLElement;
    const feedbackElem = formElem.querySelector('.feedback');

    if (!feedbackElem) {
      return;
    }

    feedbackElem.scrollIntoView({ behavior: 'smooth' });
  }

  private setFeedbackVariants(rating: number): void {
    const questionItem = this.feedback?.questions.find(item => item.rating === rating);
    const dishModalWrapper = document.querySelector('.dish-feedback-modal');
    const modalWrapperElement = dishModalWrapper.shadowRoot.querySelector('.modal-wrapper');
    modalWrapperElement.setAttribute('style', 'bottom: 0 !important');

    if (!this.isRatingSet) {
      modalWrapperElement.setAttribute('style', 'bottom: -308px !important');
    }

    if (!questionItem) {
      return;
    }

    this.form?.get('answers').patchValue([]);
    this.questions$.next(questionItem?.answers || []);
  }

  private buildForm(): void {
    this.form = this.fb.group({
      rating: [null, [Validators.required, Validators.min(1)]],
      answers: [[]],
      comment: [''],
    });
  }

  private patchForm(): void {
    const rating = this.rating || this.previousDishFeedback?.rating;
    const comment = this.feedback?.comment;
    const answers = this.rating === this.previousDishFeedback?.rating ? this.feedback?.answers : [];

    this.form.patchValue({
      rating,
      comment,
      answers,
    });
  }

  private save(rating?): Observable<any> {
    const data = this.form.getRawValue();

    const payload: FeedbackToSend = {
      ...data,
      rating: rating ? rating : data.rating,
      isFinal: false,
    };

    if (data.rating === 0 || data.rating === null) {
      return;
    }

    return this.feedbackApiService.sendAnswers(this.feedback.feedbackId, payload);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
