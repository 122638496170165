export enum LocalStorageKeysEnum {
  // auth
  token = 'token',
  refreshToken = 'refresh-token',
  codeRequestPhoneData = 'phone-aata',
  notificationsAgreement = 'notifications-agreement',
  privacyAgreement = 'privacy-agreement',

  //intro
  introSeen = 'intro-seen',

  // program
  currentProgram = 'currentProgram',

  // family
  isFamily = 'isFamily',
  isHideFamily = 'isHideFamily',

  // payment
  startedPaymentId = 'startedPaymentId',
  paymentId = 'paymentId',
  createdSubscriptionGuid = 'createdSubscriptionGuid',
  shouldTrackPaymentSuccess = 'shouldTrackPaymentSuccess',
  shouldClosePaymentModal = 'shouldClosePaymentModal',

  // analytics
  visitorId = 'visitorId',

  // notification data
  lastNotification = 'lastNotification',
  lastUpdate = 'lastUpdate',
  deviceInfo = 'deviceInfo',

  selectedDishForPortion = 'selectedDishForPortion',
  replacementDishes = 'replacementDishes',
  replacableDishMealType = 'replacableDishMealType',
  activeSubscription = 'activeSubscription',
  selectedDishToReplace = 'selectedDishToReplacef',
}
