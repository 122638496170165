import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, Self } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ClientMenuSelectors, ReplacementDiffDishes } from '@store/client-menu';
import { NgOnDestroyService } from '@shared/services';
import { getDatesFromPackages, onlyUnique } from '@shared/utils';
import { ModalController } from '@ionic/angular';
import { ClientSubscriptionPackage, PaymentDetails } from '@shared/models';
import { LoadCards, LoadPaymentDetails, PaymentSelectors } from '@store/payment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements OnInit {
  @Input() isImportant = false;

  public customizationPrice$: Observable<number> = inject(Store).select(ClientMenuSelectors.customizationPrice);
  public activeSubscriptionDetails = inject(Store).selectSnapshot(ClientMenuSelectors.subscriptionDetails);
  public startedPaymentId$: Observable<string> = inject(Store).select(PaymentSelectors.startedPaymentId);
  public paymentDetails$: Observable<PaymentDetails> = inject(Store).select(PaymentSelectors.paymentDetails);

  public packagesDates$: Observable<string>;
  public countdown: string | null = null;
  private intervalId: any;

  constructor(
    private store: Store,
    private modalCtrl: ModalController,
    private router: Router,
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.packagesDates$ = this.store
      .select(ClientMenuSelectors.replacementsInSubscription)
      .pipe(map((data: ReplacementDiffDishes[]) => this.getDates(data)));
    this.listenPaymentInfo();
  }

  private listenPaymentInfo(): void {
    this.startedPaymentId$
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        tap(paymentGuid => {
          this.store.dispatch([new LoadCards(paymentGuid), new LoadPaymentDetails(paymentGuid)]);
          this.listenPaymentDetails();
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  private listenPaymentDetails(): void {
    this.paymentDetails$
      .pipe(
        distinctUntilChanged(),
        tap(data => {
          this.getRepaymentStatus(data?.canBeRepaidAfterDate);
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  public canBeRepaidOrCanceled(canBeRepaidOrCanceledAfter: string): boolean {
    if (!canBeRepaidOrCanceledAfter) {
      return false;
    }

    const now = new Date();
    this.changeDetectorRef.markForCheck();
    return new Date(canBeRepaidOrCanceledAfter) > now;
  }

  public getRepaymentStatus(canBeRepaidOrCanceledAfter: string | null | undefined): void {
    const targetDate = new Date(canBeRepaidOrCanceledAfter);

    if (isNaN(targetDate.getTime()) || !canBeRepaidOrCanceledAfter) {
      this.countdown = null;
      return;
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      const now = new Date();
      const differenceInMs = targetDate.getTime() - now.getTime();

      if (differenceInMs <= 0) {
        clearInterval(this.intervalId);
        this.countdown = null;
        this.changeDetectorRef.markForCheck();
        return;
      }

      const seconds = Math.floor((differenceInMs / 1000) % 60);
      const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);
      const hours = Math.floor((differenceInMs / (1000 * 60 * 60)) % 24);
      const days = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
      const pad = (num: number) => num.toString().padStart(2, '0');

      let countdown = '';

      if (days > 0) {
        countdown += `${pad(days)}d `;
      }
      if (hours > 0 || days > 0) {
        countdown += `${pad(hours)}h `;
      }
      countdown += `${pad(minutes)}m ${pad(seconds)}s`;
      this.countdown = countdown.trim();
      this.changeDetectorRef.markForCheck();
    }, 1000);
  }

  public cancel(): void {
    this.modalCtrl.dismiss(null, 'erase');
  }

  public confirm(): void {
    this.modalCtrl.dismiss(true, 'confirm');
  }

  public closeModal() {
    this.modalCtrl.dismiss(null, 'cancel');
    this.router.navigate(['/']);
  }

  private getDates(replacements: ReplacementDiffDishes[]): string {
    const packages = this.getChangedPackages(replacements);

    return getDatesFromPackages(packages);
  }

  private getChangedPackages(data: ReplacementDiffDishes[]): ClientSubscriptionPackage[] {
    return data
      .map(item => item.packageId)
      .filter(onlyUnique)
      .map(packageId => this.activeSubscriptionDetails.packageItems.find(item => item.packageId === packageId))
      .filter(Boolean);
  }
}
