import { ChangeDetectionStrategy, Component, inject, NgZone, OnInit, Self } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar } from '@capacitor/status-bar';
import { IonModal, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Device } from '@capacitor/device';
import { register } from 'swiper/element/bundle';

import { BrowserLoadingScenarioService, NetworkService, AnalyticsService, NgOnDestroyService, ToastService } from '@shared/services';
import { InitProgram } from '@store/program';
import { Feedback, FeedbackToSend } from '@shared/models';
import { OneSignalInit, SetupBindingToken } from '@store/notifications';
import { FeedbackSelectors, GenerateDeliveryFeedback, GenerateMenuFeedback, SendAnswers } from '@store/feedback';
import { AuthSelectors } from './store';
import { LoadNotifications, LoadSubscriptions } from '@store/client-menu';
import { LoadMinimumPrices } from '@store/global';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [BrowserLoadingScenarioService, NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private isAuthorized$: Observable<boolean> = inject(Store).select(AuthSelectors.isAuthorized);
  public isOpenModal$ = new BehaviorSubject(false);
  public feedback$: Observable<Feedback> = inject(Store)
    .select(FeedbackSelectors.feedback)
    .pipe(
      filter(Boolean),
      tap(() => this.isOpenModal$.next(true)),
    );

  public isFeedbackSent$: Observable<boolean> = inject(Store).select(FeedbackSelectors.isFeedbackSent);
  public hasConnection$ = this.networkService.hasConnection$;

  constructor(
    private zone: NgZone,
    private platform: Platform,
    private store: Store,
    private toastService: ToastService,
    private networkService: NetworkService,
    private analyticsService: AnalyticsService,
    private browserLoadingScenarioService: BrowserLoadingScenarioService,
    @Self() private ngOnDestroy$: NgOnDestroyService,
  ) {
    register();
    this.initializeApp();
  }

  async ngOnInit(): Promise<void> {
    await this.browserLoadingScenarioService.init();

    const { platform } = await Device.getInfo();
    this.store.dispatch([new InitProgram(), new LoadSubscriptions(), new LoadNotifications()]);

    this.listenAuthorized(platform);

    if (platform !== 'web') {
      this.networkService.init();
      this.analyticsService.init();
    }

    this.feedback$.subscribe();
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid')) {
        StatusBar.setOverlaysWebView({ overlay: false });
      }

      // Слушаем deeplinks, подробности тут --> https://capacitorjs.com/docs/guides/deep-links#angular
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2

          this.presentToast('bottom', 'Переход по deep-link!', JSON.stringify(event));

          // If no match, do nothing - let regular routing
          // logic take over
        });
      });
    });
  }

  private async presentToast(position: 'top' | 'middle' | 'bottom', header: string, message: string) {
    if (environment.production) {
      return;
    }

    await this.toastService.presentToastSuccess(position, header, message);
  }

  private listenAuthorized(platform: 'ios' | 'android' | 'web'): void {
    this.isAuthorized$
      .pipe(
        distinctUntilChanged(),
        tap(() => this.store.dispatch(new LoadMinimumPrices())),
        filter(Boolean),
        debounceTime(2000),
        tap(() => {
          if (platform !== 'web') {
            this.initNotifications();
          }

          this.initDeliveryFeedback();
          this.initMenuFeedback();
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  private initNotifications(): void {
    this.store.dispatch(new OneSignalInit());

    setTimeout(() => {
      this.store.dispatch(new SetupBindingToken());
    }, 2000);
  }

  /**
   * При авторизации проверяем, нужно ли открыть модальное окно фидбека доставки
   */
  private initDeliveryFeedback(): void {
    this.store.dispatch(new GenerateDeliveryFeedback());
  }

  /**
   * При авторизации проверяем, нужно ли открыть модальное окно фидбека меню
   */
  private initMenuFeedback(): void {
    this.store.dispatch(new GenerateMenuFeedback());
  }

  public sendFeedback({ feedbackId, feedbackToSend }: { feedbackId: string; feedbackToSend: FeedbackToSend }, modal: IonModal): void {
    this.store.dispatch(new SendAnswers(feedbackId, feedbackToSend));
    modal.setCurrentBreakpoint(0.9);
  }

  public async closeFeedbackModal(isShowBanner: boolean, modal: IonModal): Promise<void> {
    await modal.dismiss();
  }
}
