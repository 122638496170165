<div
  class="wrapper"
  *ngIf="{paymentDetails: paymentDetails$ | async
} as data"
>
  <app-icon *ngIf="countdown" icon="modal-close" class="icon-close" (click)="closeModal()"></app-icon>

  <ng-container *ngIf="customizationPrice$ | async; else noPrice">
    <div class="modal-header">
      <h2 class="modal-title">Обратите внимание!</h2>

      <div *ngIf="!isImportant; else important" class="modal-text text--16">
        <ul class="modal-list">
          <li>
            После оплаты вы больше не сможете изменять меню
            <span *ngIf="packagesDates$ | async" class="dates"> на {{ packagesDates$ | async }}</span>
          </li>
          <li>Если не оплатить, привезем стандартное меню</li>
          <li><b>Для заменённых блюд информация о дне питания на этикетке может быть некорректной</b></li>
        </ul>
      </div>
    </div>

    <ng-template #important>
      <p>У вас есть неоплаченные блюда на {{ packagesDates$ | async }} на {{ customizationPrice$ | async }} рублей</p>
    </ng-template>

    <div class="modal-footer">
      <div class="modal-footer__countdown" *ngIf="countdown">
        <p>{{ countdown }}</p>
      </div>
      <div class="modal-buttons">
        <button
          (click)="cancel()"
          type="button"
          [disabled]="canBeRepaidOrCanceled(data.paymentDetails?.canBeRepaidAfterDate)"
          class="btn btn--bordered"
        >
          {{ isImportant ? 'Сбросить' : 'Отменить' }}
        </button>
        <button
          (click)="confirm()"
          type="button"
          [disabled]="canBeRepaidOrCanceled(data.paymentDetails?.canBeRepaidAfterDate)"
          class="btn btn-base"
        >
          Оплатить
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #noPrice>
    <div class="modal-header">
      <h2 class="modal-title">Обратите внимание!</h2>

      <div class="modal-text text--16">
        <ul class="modal-list">
          <li>
            После подтверждения вы больше не сможете изменять меню
            <span *ngIf="packagesDates$ | async" class="dates"> на {{ packagesDates$ | async }}</span>
          </li>
          <li>Если не подтвердить, привезем стандартное меню</li>
          <li><b>Для заменённых блюд информация о дне питания на этикетке может быть некорректной</b></li>
        </ul>
      </div>
    </div>

    <div class="modal-footer">
      <div class="modal-footer__countdown" *ngIf="countdown">
        <p>{{ countdown }}</p>
      </div>
      <div class="modal-buttons">
        <button
          (click)="cancel()"
          type="button"
          [disabled]="canBeRepaidOrCanceled(data.paymentDetails?.canBeRepaidAfterDate)"
          class="btn btn--bordered"
        >
          Отменить
        </button>
        <button
          (click)="confirm()"
          type="button"
          [disabled]="canBeRepaidOrCanceled(data.paymentDetails?.canBeRepaidAfterDate)"
          class="btn btn-base"
        >
          Подтвердить
        </button>
      </div>
    </div>
  </ng-template>
</div>
